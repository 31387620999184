import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo"/>
          <h1>Bienvenido a QuizziFit</h1>
          <p>
            QuizziFit.com es una plataforma innovadora diseñada para potenciar el aprendizaje continuo en equipos de
            desarrollo con píldoras o tips diarias y cuestionarios o quizzes diarios.
          </p>
          <p>
            Estamos construyendo la herramienta y pronto la verás en acción
          </p>
      </header>
</div>
);
}

export default App;
